import React from 'react';
import { Toaster } from 'react-hot-toast';
import JoinPage from './pages/Join';

function App() {
  return (
    <>
      <JoinPage />
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 5000,
          style: {
            background: '#333',
            color: '#fff',
          },
        }}
      />
    </>
  );
}

export default App;
