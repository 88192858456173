import React, { useState, useCallback } from 'react';
import style from './Timer.module.scss';
import toast from 'react-hot-toast';
import { addContact } from '../../../../services/brevo';

const Timer = ({ className }) => {
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      // Send data to presale.gg API
      const response = await fetch('https://api.presale.gg/v1/projects/bullionaire/leads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) throw new Error('Failed to submit email');

      // Track with Brevo
      if (window.Brevo) {
        window.Brevo.push(['identify', {
          email: email
        }]);
        window.Brevo.push(['trackEvent', 'whitelist_signup', {
          email: email,
          event_type: 'Whitelist Registration'
        }]);
      }

      // Add contact to Brevo list
      await fetch('https://api.brevo.com/v3/contacts', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_BREVO_API_KEY
        },
        body: JSON.stringify({
          email: email,
          listIds: [parseInt(process.env.REACT_APP_BREVO_LIST_ID)],
          updateEnabled: true
        })
      });

      // Track with Facebook Pixel
      if (window.fbq) {
        window.fbq('track', 'Lead');
      }
      
      toast.success('Successfully submitted!');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <div className={`${style.timer} ${className}`}>
        <div className={style.timer__title}>
          Sign up to enter the whitelist
        </div>

        <div className={style.timerForm}>
          <label className={style.timerForm__input}>
            <input 
              value={email} 
              onChange={handleEmailChange}
              type="email"
              inputMode="email"
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
              required
              placeholder="E-mail"
              aria-label="Email address"
              autoComplete="email"
            />
          </label>
          <button 
            className={style.timerForm__btn}
            onClick={handleSubmit}
            type="button"
            disabled={sending}
          >
            {sending ? 'Submitting...' : 'Submit'}
          </button>
        </div>

        <div className={style.timer__text_wrapper}>
          Get exclusive early access to the Bullionaire presale
        </div>
      </div>

      <div className={style.community}>
        <div className={style.community__title}>
          Join Our Community
        </div>
        <div className={style.community__desc}>
          Stay updated with the latest news, announcements, and exclusive offers by joining our vibrant community
        </div>
        <div className={style.community__social}>
          <a 
            href="https://x.com/bullionairecoin?s=21&t=TH9aygZjlns91mvPsvYDgQ" 
            target="_blank" 
            rel="noopener noreferrer"
            className={style.community__social_btn}
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
          </a>
          <a 
            href="https://t.me/bullionairechannel" 
            target="_blank" 
            rel="noopener noreferrer"
            className={style.community__social_btn}
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z"/>
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default Timer;
